import {PROJECT_VARIABLES} from './project_variables';

export const environment = {
    production: true,
    ENV: PROJECT_VARIABLES.environments.DEVELOPMENT,
    API_SERVER: PROJECT_VARIABLES.api_server.DEVELOPMENT,
    SENTRY_DNS: PROJECT_VARIABLES.SENTRY_DNS,
    SEGMENT_API_KEY: PROJECT_VARIABLES.segment_api_key.DEVELOPMENT,
    IMAGE_BUCKET: `https://s3-eu-west-1.amazonaws.com/${PROJECT_VARIABLES.aws_domains.DEVELOPMENT}`,
    DEFAULT_LOCALE: 'en-US',
    LOCALES: [
        {
            name: 'English',
            code: 'en-US'
        },
        {
            name: 'Nederlands',
            code: 'nl-NL'
        },
        {
            name: 'Français',
            code: 'fr-BE'
        },
        {
            name: 'Deutsch',
            code: 'de-DE'
        }
    ],
    ENVIRONMENTS: {
        LOCAL: 'local',
        DEVELOPMENT: 'development',
        DEVELOPMENT_PD: 'development-pd',
        ACCEPTANCE: 'acceptance',
        PRODUCTION: 'production'
    },
    AUTH0_AUDIENCE: PROJECT_VARIABLES.auth0_audience.DEVELOPMENT,
    AUTH0_TENANT: PROJECT_VARIABLES.auth0_tenant.DEVELOPMENT,
    AUTH0_DOMAIN: `${PROJECT_VARIABLES.auth0_tenant.DEVELOPMENT}.auth0.com`,
    OAUTH_CLIENT_ID: PROJECT_VARIABLES.oauth_client_id
};
